<template>
  <div
    v-if="
      this.result.valueLine.operator != '' &&
      this.result.valueLine.operator != '0'
    "
  >
    <defaultFieldTypes
      :fieldAttributes="{
        ...fieldAttributes,
        associatedOption: associatedOption,
      }"
      :field="field"
      :templateContent="result"
      v-on="$listeners"
    :value="value"></defaultFieldTypes>
  </div>
</template>

<script>
import defaultFieldTypes from "@/commonComponents/defaultFieldTypes.vue";
import requests from '@/js/requests';
export default {
  components: { defaultFieldTypes },
  props: ["fieldAttributes", "field", "result","value"],
  data() {
    return { associatedOption: [] };
  },
  created() {
    this.getOptions();
  },
  watch: {
    "result.valueLine.operator": function () {
      this.getOptions();
    },
  },
  methods: {
    getOptions() {
      let countryVal = this.result.valueLine.country;
      let operatorVal = this.result.valueLine.operator;
      //let caller = this;
      if (countryVal != "" && operatorVal != "" && operatorVal != "0") {
        let options = {
          function: "getRatType",
          ratOperatorval: operatorVal,
          ratCountryval: countryVal,
        };
        let caller = this;
        requests.frameworkAxiosRequest({
          method: 'post',
          url: 'serve.php?f=testing&f2=configureAndRunTests',
          data: options,
        })
            .then(function (response) {
            caller.associatedOption = response.data.result.json.ratTypeList;
          })
          .catch(function () {
            //handle error
          });
      }
    },
  },
};
</script>